import AppProvider from 'providers/AppProvider';
import Router from 'routes/router';

const App = () => {
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  );
};

export default App;
