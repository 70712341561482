import React from 'react';

import StyledTextarea, {
  StyledButtonFormat,
  StyledDivTextarea,
} from './styled';
import formatAndReturnComponentText from './formatting';
import Flexbox from 'components/Flexbox/Flexbox';

const TextArea = ({
  name,
  placeholder = '',
  minRows = 3,
  onChange,
  value,
  unicodeBidi,
  overrideStyles,
  divType = false,
  onEnlargingChange,
  selectedText,
  formatName,
  format,
  formatedValue,
}) => {
  return divType ? (
    <>
      <Flexbox gap="10px">
        <StyledButtonFormat
          onClick={onEnlargingChange(value, format, null, 36, formatName)}
        >
          H1
        </StyledButtonFormat>
        <StyledButtonFormat
          onClick={onEnlargingChange(value, format, null, 32, formatName)}
        >
          H2
        </StyledButtonFormat>
        <StyledButtonFormat
          onClick={onEnlargingChange(value, format, null, 26, formatName)}
        >
          H3
        </StyledButtonFormat>
        <StyledButtonFormat
          onClick={onEnlargingChange(value, format, null, 24, formatName)}
        >
          H4
        </StyledButtonFormat>
        <StyledButtonFormat
          onClick={onEnlargingChange(value, format, null, 20, formatName)}
        >
          H5
        </StyledButtonFormat>
        <StyledButtonFormat
          onClick={onEnlargingChange(value, format, null, 18, formatName)}
        >
          H6
        </StyledButtonFormat>
        <StyledButtonFormat
          style={{ cursor: 'pointer' }}
          onClick={onEnlargingChange(value, format, null, 14, formatName)}
        >
          P
        </StyledButtonFormat>
        <StyledButtonFormat
          style={{ cursor: 'pointer' }}
          onClick={onEnlargingChange(value, format, null, 12, formatName)}
        >
          Small
        </StyledButtonFormat>
      </Flexbox>
      <StyledDivTextarea
        contentEditable
        onBlur={onChange}
        id={name}
        style={{ unicodeBidi: unicodeBidi ? 'plaintext' : 'unset' }}
      >
        {formatedValue ||
          (Object.keys(format).length !== 0
            ? formatAndReturnComponentText(value, format, null, null)
            : value)}
      </StyledDivTextarea>
    </>
  ) : (
    <StyledTextarea
      name={name}
      placeholder={placeholder}
      minRows={minRows}
      onChange={onChange}
      value={value || ''}
      style={{ unicodeBidi: unicodeBidi ? 'plaintext' : 'unset' }}
      sx={{ ...overrideStyles }}
    ></StyledTextarea>
  );
};

export default TextArea;
