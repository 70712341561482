export const getSelectedIDs = (rows) => {
  const ids = [];

  rows.forEach((row) => {
    const currentId = row.original.id;
    ids.push(currentId);
  });

  return ids;
};

export const getIDsFromObj = (data) => {
  const ids = [];

  data.forEach((item) => {
    const currentId = item.id;
    ids.push(currentId);
  });

  return ids;
};
