import { useDispatch, useSelector } from 'react-redux';

import { setDashboardUpdate } from './DashboardSlice';

const useDashboardDispatcher = () => {
  const dispatch = useDispatch();
  const { updateDashboard } = useSelector((state) => state.dashboardReducer);

  const setDashboardUpdating = (state) => {
    return dispatch(setDashboardUpdate(state));
  };

  return { setDashboardUpdating, updateDashboard };
};

export default useDashboardDispatcher;
