import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  updateDashboard: false,
};

const dashboardrSlice = createSlice({
  name: 'DASHBOARD',
  initialState,
  reducers: {
    setDashboardUpdate: (state, payload) => {
      state.updateDashboard = payload;
    },
  },
});

export const { setDashboardUpdate } = dashboardrSlice.actions;
export default dashboardrSlice.reducer;
