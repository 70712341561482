const getStartEnd = (str, sub) => [
  str.indexOf(sub),
  str.indexOf(sub) + sub.length - 1,
];

// const formatAndReturnComponent = (
//   text,
//   formatRules,
//   selectedText,
//   selectedFontSize,
// ) => {
//   // // console.log(formatRules, text, selectedText);

//   if (!formatRules) return <>{text}</>;

//   // if (Object.keys(formatRules).length !== 0 && formatRules?.formats === null)
//   //   return <>{text}</>;

//   const { formats } = formatRules;

//   if (selectedText) {
//     const componentArray = [];

//     const [startSelectedIndex, endSelectedIndex] = getStartEnd(
//       text,
//       selectedText,
//     );
//     const endIndex = endSelectedIndex + 1;

//     const newFormats = {};

//     newFormats[startSelectedIndex] = { size: selectedFontSize };
//     newFormats[endIndex] = { size: 12 };

//     for (let key in formats) {
//       const index = key;
//       const currentEl = formats[key];

//       if (index < startSelectedIndex) {
//         newFormats[key] = currentEl;
//       }

//       if (index > endSelectedIndex) {
//         newFormats[key] = currentEl;
//       }
//     }

//     const objectKeys = Object.keys(newFormats);

//     for (let key in newFormats) {
//       const index = key;
//       const arrayIndex = objectKeys.indexOf(index);
//       const nextIndex = objectKeys[arrayIndex + 1];

//       const fontSize = `${newFormats[key].size}px`;
//       const subString = text.substring(index, nextIndex && nextIndex);

//       const component = <span style={{ fontSize }}>{subString}</span>;
//       componentArray.push(component);
//     }

//     return {
//       component: <>{componentArray.map((cmpnt) => cmpnt)}</>,
//       newFormat: newFormats,
//     };
//   } else {
//     const componentArray = [];
//     const objectKeys = Object.keys(formats);
//     for (let key in formats) {
//       const index = key;
//       const arrayIndex = objectKeys.indexOf(index);
//       const nextIndex = objectKeys[arrayIndex + 1];

//       const fontSize = `${formats[key].size}px`;
//       const subString = text.substring(index, nextIndex && nextIndex);

//       const component = <span style={{ fontSize }}>{subString}</span>;
//       componentArray.push(component);
//     }

//     return <>{componentArray.map((cmpnt) => cmpnt)}</>;
//   }
// };

const formatAndReturnComponent = (
  text,
  formatRules,
  selectedText,
  fontSize,
) => {
  // console.log(formatRules, text);
  if (formatRules && formatRules.formats) {
    const { formats } = formatRules;
    const componentArray = [];

    const [startSelectedIndex, endSelectedIndex] = getStartEnd(
      text,
      selectedText,
    );
    const endIndex = endSelectedIndex + 1;

    const newFormats = {};

    newFormats[startSelectedIndex] = { size: fontSize };
    newFormats[endIndex] = { size: 12 };

    for (let key in formats) {
      const index = key;
      const currentEl = formats[key];

      if (index < startSelectedIndex) {
        newFormats[key] = currentEl;
      }

      if (index > endSelectedIndex) {
        newFormats[key] = currentEl;
      }
    }

    const objectKeys = Object.keys(newFormats);

    for (let key in newFormats) {
      const index = key;
      const arrayIndex = objectKeys.indexOf(index);
      const nextIndex = objectKeys[arrayIndex + 1];

      const fontSize = `${newFormats[key].size}px`;
      const subString = text.substring(index, nextIndex && nextIndex);

      const component = <span style={{ fontSize }}>{subString}</span>;
      componentArray.push(component);
    }

    return {
      component: <>{componentArray.map((cmpnt) => cmpnt)}</>,
      newFormat: {
        formats: {
          ...newFormats,
        },
      },
    };
  }

  if (!formatRules) {
    const componentArray = [];

    const [startSelectedIndex, endSelectedIndex] = getStartEnd(
      text,
      selectedText,
    );
    const endIndex = endSelectedIndex + 1;

    const newFormats = {};

    newFormats['0'] = { size: 12 };
    newFormats[startSelectedIndex] = { size: fontSize };
    newFormats[endIndex] = { size: 12 };

    const objectKeys = Object.keys(newFormats);

    for (let key in newFormats) {
      const index = key;
      const arrayIndex = objectKeys.indexOf(index);
      const nextIndex = objectKeys[arrayIndex + 1];

      const fontSize = `${newFormats[key].size}px`;
      const subString = text.substring(index, nextIndex && nextIndex);

      const component = <span style={{ fontSize }}>{subString}</span>;
      componentArray.push(component);
    }

    // console.log(selectedText, text, newFormats);

    return {
      component: <>{componentArray.map((cmpnt) => cmpnt)}</>,
      newFormat: {
        formats: {
          ...newFormats,
        },
      },
    };
  }

  return text;
};

const baseFormatValue = (text, formatRules) => {
  if (text && formatRules && formatRules.formats) {
    const { formats } = formatRules;
    const componentArray = [];
    const objectKeys = Object.keys(formats);
    for (let key in formats) {
      const index = key;
      const arrayIndex = objectKeys.indexOf(index);
      const nextIndex = objectKeys[arrayIndex + 1];

      const fontSize = `${formats[key].size}px`;
      const subString = text.substring(index, nextIndex && nextIndex);

      const component = <span style={{ fontSize }}>{subString}</span>;
      componentArray.push(component);
    }

    return <>{componentArray.map((cmpnt) => cmpnt)}</>;
  }

  return text;
};

export { baseFormatValue };
export default formatAndReturnComponent;
