/* eslint-disable react-hooks/exhaustive-deps */
import Header from 'components/Layouts';
import ArticleDatatable from '../components/ArticleDatatable';
import Container from 'components/Container/Container';

const Dashboard = () => {
  return (
    <div>
      <Header />
      <Container around>
        <ArticleDatatable />
      </Container>
    </div>
  );
};

export default Dashboard;
