import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';

import store from 'store/configureStore';

import ToasterProvider from './ToasterProvider';

const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ToasterProvider />
            {children}
          </StyledEngineProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  );
};

export default AppProvider;
