import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import NProgress from 'nprogress';

const stylesBox = {
  position: 'fixed',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const stylesElementBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const ScreenLoader = ({ fullHeight = true, fullScreen = true }) => {
  const heightStyles = fullHeight ? '100vh' : '100%';
  const mainStyles = fullScreen ? stylesBox : stylesElementBox;

  NProgress.configure({
    showSpinner: false,
  });

  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={{ ...mainStyles, height: heightStyles }}>
      <CircularProgress />
    </Box>
  );
};

export default ScreenLoader;
