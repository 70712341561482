// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: #F7F7FB;
  /* background-color: #50A7C2; */
}

.ellipsis-tail {
  position: absolute !important;
  left: 0;
  padding: 0 !important;
}

.ellipsis-class {
  font-size: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;EAC7B,OAAO;EACP,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["*,\r\n*::after,\r\n*::before {\r\n  box-sizing: border-box;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: 'Roboto', sans-serif;\r\n  font-size: 16px;\r\n  font-weight: 400;\r\n  background-color: #F7F7FB;\r\n  /* background-color: #50A7C2; */\r\n}\r\n\r\n.ellipsis-tail {\r\n  position: absolute !important;\r\n  left: 0;\r\n  padding: 0 !important;\r\n}\r\n\r\n.ellipsis-class {\r\n  font-size: 16px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
