import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

import palette from 'theme/palette';

const HeaderRoot = styled('header')(() => ({
  padding: '20px 0',
}));

const HeaderInner = styled('div')(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '40px',
  padding: '20px 0',
}));

const HeaderLink = styled(NavLink)(() => ({
  fontFamily: 'Roboto',
  fontSize: '16px',
  lineHeight: '1.3',
  color: palette.text,
  textDecoration: 'none',
  transition: 'all .3s',
  fontWeight: 500,
  textTransform: 'uppercase',

  '&:hover': {
    color: palette.primary,
  },

  '&.active': {
    color: palette.primary,
  },
}));

export { HeaderInner, HeaderLink };
export default HeaderRoot;
