import ReactDOM from 'react-dom';
import React from 'react';

import App from './App';

import './styles/normalize.css';
import './styles/index.css';
import './styles/App.css';
import 'simplebar/dist/simplebar.min.css';
import 'react-quill/dist/quill.snow.css';

ReactDOM.render(<App />, document.getElementById('root'));
