import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import { Fragment, useState } from 'react';
import dayjs from 'dayjs';

// import EdtiArticleModal from '../components/EdtiArticleModal';
import EditShortlistModal from '../components/EditShortlistModal';
import { Paragraph } from 'components/Typography';
import Flexbox from 'components/Flexbox/Flexbox';
import Popup from 'components/Popup/Popup';

import ArticleCheckModule from '../modules/ArticleCheckModule';

import palette from 'theme/palette';

const columnArticleShape = [
  {
    minWidth: 150,
    Header: '',
    accessor: 'checkboxes',
    Cell: ({ row }) => {
      return (
        <>
          <ArticleCheckModule rowData={row.original} />
        </>
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Section',
    accessor: 'mainTitle',
    Cell: ({ row }) => {
      const { mainTitle, arabicMainTitle } = row.original;

      return (
        <Flexbox flexDirection="column" gap="10px">
          <Paragraph
            content={mainTitle}
            overrideStyles={{
              lineHeigth: '1.4',
              textAlign: 'left',
              fontSize: '13px',
            }}
            spacing
          />

          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: 'rgba(224, 224, 224, 1)',
            }}
          ></div>

          <Paragraph
            content={arabicMainTitle}
            overrideStyles={{
              lineHeigth: '1.4',
              textAlign: 'right',
              fontSize: '13px',
            }}
            spacing
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 200,
    Header: 'Title',
    accessor: 'title',
    Cell: ({ row }) => {
      const { title, url, arabicTitle } = row.original;
      const [open, setOpen] = useState(false);
      const [openArabic, setOpenArabic] = useState(false);

      const handleState = () => {
        setOpen((prevState) => !prevState);
      };

      const handleStateArabic = () => {
        setOpenArabic((prevState) => !prevState);
      };

      const handleClick = () => {
        window.open(url, '_blank');
      };

      return (
        (title || arabicTitle) && (
          <Fragment>
            <Flexbox flexDirection="column" gap="10px">
              <Box
                onClick={handleState}
                sx={{
                  cursor: 'pointer',
                  span: {
                    fontWeight: 400,
                  },
                }}
              >
                <EllipsisText text={title || ''} length={50} />
              </Box>

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                }}
              ></div>

              <Box
                onClick={handleStateArabic}
                sx={{
                  cursor: 'pointer',
                  textAlign: 'right',
                  span: {
                    position: 'relative',
                    paddingLeft: '13px',
                    fontWeight: 400,
                  },
                }}
              >
                <EllipsisText
                  text={arabicTitle || ''}
                  length={50}
                  tailClassName="ellipsis-tail"
                />
              </Box>
            </Flexbox>

            <Popup open={open} onClose={handleState} header>
              <Paragraph
                content={title}
                overrideStyles={{
                  lineHeight: '1.4',
                  textAlign: 'left',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
                spacing
              />
            </Popup>

            <Popup open={openArabic} onClose={handleStateArabic} header>
              <Paragraph
                content={arabicTitle}
                overrideStyles={{
                  lineHeight: '1.4',
                  textAlign: 'left',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
                spacing
              />
            </Popup>
          </Fragment>
        )
      );
    },
  },
  {
    minWidth: 200,
    Header: 'Author',
    accessor: 'author',
    Cell: ({ row }) => {
      const { author } = row.original;

      return (
        <Flexbox flexDirection="column" gap="10px">
          <Paragraph
            content={author}
            overrideStyles={{
              lineHeigth: '1.4',
              textAlign: 'left',
              fontSize: '13px',
            }}
            spacing
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 200,
    Header: 'Summary',
    accessor: 'summary',
    Cell: ({ row }) => {
      const { summary, arabicSummary } = row.original;
      const [open, setOpen] = useState(false);
      const [openArabic, setOpenArabic] = useState(false);

      const handleState = () => {
        setOpen((prevState) => !prevState);
      };

      const handleStateArabic = () => {
        setOpenArabic((prevState) => !prevState);
      };

      return (
        (summary || arabicSummary) && (
          <Fragment>
            <Flexbox flexDirection="column" gap="10px">
              <Box
                onClick={handleState}
                sx={{
                  cursor: 'pointer',
                  span: {
                    fontWeight: 400,
                  },
                }}
              >
                <EllipsisText text={summary || ''} length={50} />
              </Box>

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                }}
              ></div>

              <Box
                onClick={handleStateArabic}
                sx={{
                  cursor: 'pointer',
                  textAlign: 'right',
                  span: {
                    position: 'relative',
                    paddingLeft: '13px',
                    fontWeight: 400,
                  },
                }}
              >
                <EllipsisText
                  text={arabicSummary || ''}
                  length={50}
                  tailClassName="ellipsis-tail"
                />
              </Box>
            </Flexbox>

            <Popup open={open} onClose={handleState} header>
              <Paragraph
                content={summary}
                overrideStyles={{
                  lineHeight: '1.4',
                  textAlign: 'left',
                }}
                spacing
              />
            </Popup>

            <Popup open={openArabic} onClose={handleStateArabic} header>
              <Paragraph
                content={arabicSummary}
                overrideStyles={{
                  lineHeight: '1.4',
                  textAlign: 'right',
                }}
                spacing
              />
            </Popup>
          </Fragment>
        )
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Publishing date',
    accessor: 'createdAt',
    Cell: ({ row }) => {
      const { createdAt } = row.original;
      return createdAt ? (
        <Paragraph content={dayjs(createdAt).format('DD/MM/YYYY h:mm:ss A')} />
      ) : (
        ''
      );
    },
  },
  {
    minWidth: 50,
    Header: 'Edit',
    accessor: 'edit',
    textAlign: 'center',
    Cell: ({ row }) => {
      const data = row.original;

      const [open, setOpen] = useState(false);

      const handleState = () => {
        setOpen((prevState) => !prevState);
      };

      return (
        <Fragment>
          <IconButton component="span" disableRipple onClick={handleState}>
            <EditIcon
              sx={{
                color: palette.border,
              }}
            />
          </IconButton>

          <EditShortlistModal open={open} onClose={handleState} data={data} />
        </Fragment>
      );
    },
  },
];

export default columnArticleShape;
