import { SvgIcon, TextField, styled } from '@mui/material';

import palette from 'theme/palette';

const StyledSearch = styled(TextField)(() => ({
  input: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 400,
    padding: '11px 10px 11px 6px',
    color: palette.text,
    '&::placeholder': {
      fontSize: '14px',
      color: palette.text,
      fontWeight: 500,
    },
  },
  fieldset: {
    borderColor: palette.active,
  },
  '& .MuiInputBase-root': {
    padding: '0',
    '&.Mui-focused': {
      fieldset: {
        borderColor: `${palette.border} !important`,
        borderWidth: '1px !important',
      },
    },
    '&:hover': {
      fieldset: {
        borderColor: `${palette.border} !important`,
        borderWidth: '1px !important',
      },
    },
  },
  '& .MuiFilledInput-root': {
    borderRadius: '0.5rem',
    backgroundColor: 'transparent !important',
    border: '1px solid transparent',
    // borderColor: '#919eab52',
    transition: 'all .3s',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      //   borderColor: palette.border,
    },
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: '#d32f2f',
  },
  '& .MuiFilledInput-root.Mui-error': {
    borderColor: '#d32f2f',
  },
  '& .MuiFilledInput-root.Mui-focused': {
    borderColor: `${palette.border} !important`,
    borderWidth: '1px !important',
  },
  '& .MuiFormLabel-root': {
    color: 'rgb(145, 158, 171)',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '1.2em',
    transform: 'translate(12px, 18px) scale(1)',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    borderColor: palette.text,
    opacity: '0.7',
    transform: 'translate(12px, 8px) scale(0.85)',
  },
  '& .MuiInputLabel-shrink.MuiInputLabel-filled': {
    transform: 'translate(12px, 8px) scale(0.85)',
    borderColor: '#d32f2f',
  },
  '& .MuiOutlinedInput-input': {
    fontWeight: 500,
    color: palette.text,
  },
}));

const SearchIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M8.80758 0C3.95121 0 0 3.95121 0 8.80758C0 13.6642 3.95121 17.6152 8.80758 17.6152C13.6642 17.6152 17.6152 13.6642 17.6152 8.80758C17.6152 3.95121 13.6642 0 8.80758 0ZM8.80758 15.9892C4.84769 15.9892 1.62602 12.7675 1.62602 8.80762C1.62602 4.84773 4.84769 1.62602 8.80758 1.62602C12.7675 1.62602 15.9891 4.84769 15.9891 8.80758C15.9891 12.7675 12.7675 15.9892 8.80758 15.9892Z"
        fill="currentColor"
      />
      <path
        d="M19.7617 18.6124L15.1005 13.9511C14.7829 13.6335 14.2685 13.6335 13.9509 13.9511C13.6332 14.2684 13.6332 14.7834 13.9509 15.1007L18.6121 19.762C18.7709 19.9208 18.9788 20.0002 19.1869 20.0002C19.3948 20.0002 19.6029 19.9208 19.7617 19.762C20.0794 19.4446 20.0794 18.9297 19.7617 18.6124Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  fontSize: 16,
  marginLeft: '0.5rem',
  marginRight: '0.5rem',
  color: palette.primary,
}));

export { StyledSearchIcon };
export default StyledSearch;
