import React, { useState, useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { useAsyncDebounce } from 'react-table';

import StyledSearch, { StyledSearchIcon } from './styled';

const SearchField = ({
  globalFilter,
  setGlobalFilter,
  placeholder,
  overrideStyles,
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || '');
  }, 400);

  useEffect(() => {
    if (!globalFilter) {
      setValue('');
    }
  }, [globalFilter]);

  const renderAdorment = (
    <InputAdornment position="end">
      <StyledSearchIcon />
    </InputAdornment>
  );

  return (
    <div>
      <StyledSearch
        value={value || ''}
        placeholder={placeholder}
        sx={overrideStyles}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        InputProps={{
          startAdornment: renderAdorment,
        }}
      />
    </div>
  );
};

export default SearchField;
