import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import datepickerIcon from './icon/datepicker.png';

import palette from 'theme/palette';

export const StyledOneDatePicker = styled(DatePicker)(() => ({
  '& .MuiInputBase-root': {
    width: '150px',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 400,
    padding: '11px 10px 11px 10px',
    color: palette.text,
    '&.Mui-focused': {
      fieldset: {
        borderColor: `${palette.border} !important`,
        borderWidth: '1px !important',
      },
    },
    '&:hover': {
      fieldset: {
        borderColor: `${palette.border} !important`,
        borderWidth: '1px !important',
      },
    },
  },
  input: {
    padding: 0,
    '&::placeholder': {
      fontSize: '14px',
      color: palette.text,
      fontWeight: 500,
    },
  },
  fieldset: {
    borderColor: palette.active,
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: '-5px',
  },
}));

export const DateIcon = () => {
  return <img src={datepickerIcon} alt="date-icon" width={20} height={20} />;
};
