import request from 'api/api.js';

export const getShortlistAll = (payload) => {
  return request({
    url: '/shortlist/all',
    method: 'POST',
    data: { ...payload },
  });
};

export const autoSortShortlist = (shortlistNumbet) => {
  return request({
    url: '/shortlist/auto-reorder/' + shortlistNumbet,
    method: 'POST',
    data: {},
  });
};

export const updateShortlist = (payload) => {
  return request({
    url: '/shortlist/update',
    method: 'POST',
    data: { ...payload },
  });
};

export const getReportAll = (shortlistNumber) => {
  return request({
    url: '/report/all/' + shortlistNumber,
    method: 'GET',
  });
};

export const createReport = (payload) => {
  return request({
    url: '/report/create-file',
    method: 'POST',
    data: { ...payload },
    responseType: 'blob',
  });
};

export const reportGenerateName = (payload) => {
  return request({
    url: '/report/generate-name',
    method: 'POST',
    data: { ...payload },
  });
};

export const removeAllShortlist = (shortlistNumber) => {
  return request({
    url: '/shortlist/remove/all/' + shortlistNumber,
    method: 'POST',
    data: {},
  });
};

export const removeOneShortlist = (id) => {
  return request({
    url: '/shortlist/remove/' + id,
    method: 'POST',
    data: {},
  });
};

export const getGoogleLink = (payload) => {
  return request({
    url: '/report/generate-link',
    method: 'POST',
    data: { ...payload },
  });
};

export const setArticleCheckbox = (payload) => {
  return request({
    url: '/article/set/checkbox',
    method: 'POST',
    data: { ...payload },
  });
};

export const reorderShortlist = (payload) => {
  return request({
    url: '/shortlist/reorder',
    method: 'POST',
    data: { ...payload },
  });
};
