import { styled } from '@mui/material';
import React from 'react';

import palette from 'theme/palette';

const StyledH2 = styled('h2')(() => ({
  fontFamily: 'Roboto',
  fontWeight: 500,
  letterSpacing: '0.25px',
  color: palette.text,
  fontSize: '20px',
  margin: 0,
}));

const H2 = ({ content }) => {
  return <StyledH2>{content}</StyledH2>;
};

export default H2;
